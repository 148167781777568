.container::-webkit-scrollbar {
  width: 8px;
  /* Tamaño del scroll en vertical */
  height: 8px;
  /* Tamaño del scroll en horizontal */
  /* display: none; */
  /* Ocultar scroll */
}
/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
  /* padding-top: 4px; */
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.container::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); */
}

/* Cambiamos el fondo cuando esté en active */
.container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}
/* Ponemos un color de fondo y redondeamos las esquinas del track */
.container::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

/* Cambiamos el fondo cuando esté en active o hover */
.container::-webkit-scrollbar-track:hover,
.container::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
/*  */